import CarIcon from '../icons/insurance-car.svg';
import CardIcon from '../icons/insurance-card.svg';
import ManIcon from '../icons/insurance-man.svg';
import MoneyIcon from '../icons/insurance-money.svg';
import TowTrackIcon from '../icons/tow-track.svg';
import AdvantagesHost from '../icons/advantages-host.svg';

export const serviceAdvantagesContent = [
  {
    key: 1,
    title: 'Простая регистрация',
    text:
      'Зарегистрируйтесь один раз по паспорту и водительским правам и получите доступ ко всем автомобилям сервиса по всей стране. Для граждан РФ, возраст от 21 года, стаж от 2-х лет',
    icon: CardIcon,
    classModifier: '-card',
  },
  {
    key: 2,
    title: 'Поиск поблизости',
    text:
      'Выберите необходимые даты и мы отобразим свободные автомобили с настоящими фотографиями и реальными отзывами от других водителей. Найдите машину рядом с домом, используя поиск на карте города, или закажите доставку к удобному времени.',
    icon: CarIcon,
    classModifier: '-car',
  },
  {
    key: 3,
    title: 'Страхование включено',
    text:
      'На Getarent все поездки застрахованы от повреждений и угона в СПАО "Ингосстрах", ответственность водителя ограничена суммой 20 000 ₽, а в случае виновности третьего лица составляет 0 ₽',
    icon: ManIcon,
    classModifier: '-man',
  },
  {
    key: 4,
    title: 'Оплата после получения',
    text:
      'Оплатите поездку банковской картой без залогов и бумажных договоров после осмотра машины у капота',
    icon: MoneyIcon,
    classModifier: '-money',
  },
  {
    key: 5,
    title: 'Помощь на дороге 24/7',
    text:
      'Мы всегда рядом. Команда поддержки работает круглосуточно для пользователей Getarent. В случае необходимости, мы вызовем эвакуатор, аварийного комиссара и поможем в любой трудной ситуации',
    icon: TowTrackIcon,
    classModifier: '-bigcar',
  },
  {
    key: 6,
    title: 'Большой выбор машин',
    text:
      'От бюджетного до премиум сегмента, с гибкой настройкой включенного пробега и большим выбором дополнительных услуг и устройств',
    icon: AdvantagesHost,
    classModifier: '-car',
  },
];
