import styled from 'styled-components';
import { Title } from '../../../styledComponents';
import { breakpoints } from '../../../constants/breakpoints';

export const CardText = styled.p`
  @media ${breakpoints.mobile} {
    white-space: pre-line;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #222222;
  }

  @media ${breakpoints.laptop} {
    font-size: 16px;
    line-height: 150%;
  }
`;

export const CardTitle = styled.h4`
  @media ${breakpoints.mobile} {
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    color: #222222;
  }

  @media ${breakpoints.laptop} {
    font-size: 24px;
  }
`;

export const Card = styled.div`
  @media ${breakpoints.mobile} {
    .icon {
      margin-bottom: 24px;
    }

    .icon.-bigcar {
      width: 100px;
      height: 100px;
    }

    ${CardTitle} {
      margin-bottom: 16px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      width: calc((100% - 192px) / 3);
      margin-right: 96px;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    .icon {
      margin-bottom: 40px;
      min-height: 103px;
    }

    .icon.-car {
      width: 150px;
      height: 103px;
    }

    .icon.-clock {
      width: 150px;
      height: 103px;
    }

    .icon.-card {
      width: 239px;
      height: 103px;
    }

    .icon.-man {
      width: 150px;
      height: 103px;
    }

    .icon.-money {
      width: 68.37px;
      height: 103px;
    }

    .icon.-bigcar {
      width: 150px;
      height: 103px;
    }

    ${CardTitle} {
      margin-bottom: 24px;
    }
  }
`;

export const InsuranceContent = styled.div`
  @media ${breakpoints.mobile} {
    ${Card} {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .btn {
      max-width: 330px;
      width: 100%;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: 1272px;
    }

    ${Card} {
      margin-bottom: ${({ oneLineCard }) => (oneLineCard ? 0 : '60px')};

      &:last-child {
        margin-bottom: 60px;
      }
    }

    .btn {
      width: auto;
    }

    .link-btn {
      margin: 0 auto;
    }
  }
`;

export const InsuranceWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      width: 100%;
      padding-top: ${({ topPadding }) => (topPadding ? '60px' : '0')};
      padding-bottom: ${({ bottomPadding }) => (bottomPadding ? '60px' : '0')};
      padding-left: 15px;
      padding-right: 15px;
    }

    ${Title} {
      text-align: ${({ LTR }) => (LTR ? 'center' : 'left')};
      margin-bottom: 15px;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      padding-top: ${({ topPadding }) => (topPadding ? '100px' : '0')};
      padding-bottom: ${({ bottomPadding }) => (bottomPadding ? '100px' : '0')};
      padding-left: 36px;
      padding-right: 36px;
    }

    ${Title} {
      text-align: ${({ LTR }) => (LTR ? 'center' : 'left')};
      margin-bottom: 25px;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      padding-left: 54px;
      padding-right: 54px;
      max-width: 1440px;
    }
  }
`;

export const InsuranceTitle = styled(Title)`
  @media ${breakpoints.mobile} {
    font-size: 32px;
    line-height: 44px;
    padding: 0 10%;
  }

  @media ${breakpoints.tablet} {
    padding: 0;
    line-height: 140%;
  }
`;

export const Description = styled.div`
  @media ${breakpoints.mobile} {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #222222;
    text-align: center;
    margin-bottom: 40px;
    padding: 0 10%;
  }

  @media ${breakpoints.tablet} {
    font-size: 32px;
    line-height: 140%;
    margin-bottom: 60px;
    padding: 0;
  }
`;
