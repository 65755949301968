import React from 'react';
// import Button from '@material-ui/core/Button';

import { StyledLink, Colored } from '../../../styledComponents';
import {
  InsuranceWrapper,
  InsuranceContent,
  Card,
  CardTitle,
  CardText,
  InsuranceTitle,
  Description,
} from './styles';

const Insurance = ({
  title = '',
  description,
  content = [],
  button,
  bottomPadding,
  topPadding,
  oneLineCard,
  LTR,
}) => {
  return (
    <InsuranceWrapper bottomPadding={bottomPadding} topPadding={topPadding} LTR={LTR}>
      <InsuranceTitle>{title}</InsuranceTitle>
      {description && <Description>{description}</Description>}
      <InsuranceContent oneLineCard={oneLineCard}>
        {content.map(({ key, title, text, icon: Icon, classModifier, link, href }) => (
          <Card key={key}>
            {Icon && <Icon className={`icon ${classModifier}`} />}
            <CardTitle>{title}</CardTitle>
            <CardText>{text}</CardText>
            {link ? (
              <StyledLink rel="noreferrer" target="_blank" title={link} href={href}>
                {link}
              </StyledLink>
            ) : null}
          </Card>
        ))}
        {/* {Boolean(button) && (
          <a href="/app/search" underline="none" className="link-btn">
            <Button variant="contained" className="btn" color="secondary" className="btn">
              Найти машину
            </Button>
          </a>
        )} */}
      </InsuranceContent>
    </InsuranceWrapper>
  );
};

export default Insurance;
